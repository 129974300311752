:root
{
  // basic color codes
  --color-base-primary: #F9F9F9;
  --color-base-secondary: #E9E9E9;
  --color-base-tertiary: #F3F3F3;

  --color-font-primary: #454545;
  //--color-font-primary-light: #AAAAAA;
  --color-font-primary-light: #454545;

  --color-font-secondary: #4C8434;

  //--color-button: #ABCB67; original
  //--color-button: #5D810E; dark
  --color-button: #17864C;
  //--color-button-hover: #869D55;
  --color-button-hover: #414B2A;
  --color-button-alternative: #FFFFFF;
  --color-button-tertiary: #FBFBFB;

  --color-mayor-top: #FFFFFF;
  --color-mayor-bottom: #F3F3F3;
  --color-mayor-name: #000000;

  --color-phone-bg: #FFFFFF;
  //--color-phone-highlight: #10A19B;
  --color-phone-highlight: #0D827E;

  //--color-title-bg: #10A19B;
  --color-title-bg: #0D827E;
  --color-title-font: #FFFFFF;
  //--color-title-font-secondary: #10A19B;
  --color-title-font-secondary: #0D827E;
  --color-border: #E5E5E5;
  --color-info: #FFF500;

  --color-error: #FF4141;

  --color-fadeout: rgba(69, 69, 69, 0.8);
  --color-dark: #171717;
  --color-shadow: rgba(0,0 ,0, 0.1);


  // colors in use
  --color-body: #F9F9F9;
  // header colors
  --header-font: #454545;
  //--header-font-active: #4C8434;
  --header-font-active: #17864C;
  --header-font-light: #AAAAAA;
  //--header-font-active-light: #ABCB67; original
  //--header-font-active-light: #5D810E; dark
  --header-font-active-light: #17864C;
  --header-submenu-bg: #FFFFFF;
  --header-submenu-bg-hover: #F3F3F3;
  --header-alternate-background: #FFFFFF;
  --header-hamburger: #A8A8A8;
  // footer colors
  --footer-bg: #454545;
  --footer-title-font: #FFFFFF;
  //--footer-title-bg: #10A19B;
  --footer-title-bg: #0D827E;
  --footer-font-color: #E5E5E5;
  // input colors
  //--input-border: #E5E5E5;
  --input-border: #454545;
  --input-background: #FFFFFF;
  --input-placeholder: #AAAAAA;

  // calendar
  --calendar-button-color: #E5E5E5;
  //--calendar-header-font: #10A19B;
  --calendar-header-font: #0D827E;
  --calendar-font: #454545;
  --calendar-today: #E5E5E5;
  //--calendar-event: #ABCB67; original
  //--calendar-event: #5D810E; dark
  --calendar-event: #17864C;
  //--calendar-event-checkbox: #10A19B;
  --calendar-event-checkbox: #0D827E;
  //--calendar-border: #E5E5E5;
  --calendar-border: #454545;
  //--calendar-weekend: #FCF4EF;
  --calendar-weekend: #FFB8B8;

  //file upload
  --upload-file-name: #000000;
  --upload-delete: #FF4141;


  // spacings
  --spacing-small: 2rem;
  --spacing-large: 3rem;
  --spacing-xlarge: 7.5rem;

  // bs container widths
  --container-width-xs: 100vw;
  --container-width-sm: 100vw;
  --container-width-md: 760px;
  --container-width-lg: 990px;
  --container-width-xl: 1012px;
}
